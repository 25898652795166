<template>
    <comp-table
        ref="comptable"
        title="标签管理"
        :columns="columns"
        edit-key="id"
        :table-api="table_url"
        :delete-api="v => '/gateway/api/sycompany/pc/label/deleteById?labelById=' + v.id"
        :formComponent="dataForm"
    >
        <template v-slot:search="data">
            <FormItem label="标签名称" :label-width="100" style="margin-bottom: 0">
                <Input v-model.trim="data.search.labelName" placeholder="请输入标签名称" style="width: 160px"></Input>
            </FormItem>
        </template>
    </comp-table>
</template>

<script>
import DataForm from "./allform.vue"
import CompTable from "../components/comp-table.vue"

export default {
    components: { CompTable },
    created() {
        setTimeout(() => {
            var list = this.$refs.comptable.$refs.elem_table.table_columns
            console.log("length", list[list.length - 1].render)
        }, 100)
    },
    data() {
        return {
            dataForm: DataForm,

            table_url: `/gateway/api/sycompany/pc/label/getLabelByPage?parentId=0`,

            columns: [
                {
                    title: "标签名称",
                    key: "labelName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "排序",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("Input", {
                            props: {
                                value: params.row.sort,
                            },
                            attrs: {
                                type: "number",
                                number: "true",
                            },
                            style: {
                                width: "100px",
                            },
                            on: {
                                "on-enter": evt => {
                                    this.onChangeSort(params.row.id, evt.target.value)
                                },
                                "on-blur": evt => {
                                    this.onChangeSort(params.row.id, evt.target.value)
                                },
                            },
                        })
                    },
                },
                {
                    title: "编码",
                    key: "labelCode",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "创建时间",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.gmtCreate) {
                            return h("div", {}, "-")
                        }

                        return h("div", {}, this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss"))
                    },
                },
                {
                    title: "下一级",
                    align: "center",
                    fixed: "right",
                    minWidth: 150,
                    render: (h, params) => {
                        return [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            // this.$core.openLayerFrame({
                                            //     type: 2,
                                            //     title: "子标签",
                                            //     content: `/page#/jointly/zilabel?parentId=${params.row.id}&labelType=${params.row.labelType}&labelName=${params.row.labelName}`,
                                            //     area: ["100%", "100%"],
                                            // })
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: "子标签",
                                                content: `/page#/shopclassifyecology?labelType=${params.row.labelType}&labelName=${params.row.labelName}&parentId=${params.row.id}`,
                                                area: ["100%", "100%"],
                                            })
                                        },
                                    },
                                },
                                "子标签"
                            ),
                        ]
                    },
                },
            ],

            //查询参数
            searchForm: {
                keyword: "",
            },
        }
    },
    methods: {
        /**
         * 修改排序
         */
        onChangeSort(id, val) {
            if (val < 0) {
                return this.$Message.error({
                    content: "排序值不能负数",
                    background: true,
                })
            }

            this.$post(`/gateway/api/sycompany/pc/label/updateLabelSeq?id=${id}&sort=${val}`).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;

    /deep/ .ivu-menu {
        width: 160px !important;
    }
}

.none-modal {
    /deep/ .ivu-modal-close {
        display: none;
    }
}
</style>
